/* Desktop Version */
@media (min-width: 1000px){
    .contactcontainer{
        width: 7.5%;
        height: 7.5%;
    }
}

/* Mobile Version */
@media (max-width: 1000px){
    .contactcontainer{
        width: 25%;
        height: 25%;
    }
}
  
  .contacticon{
    max-width: 100%;
    max-height: 100%;
  
  }
  
  .contactcontainer .contactwrapper:hover a img {
    animation-name: iconspin;
    animation-duration: 1s;
    animation-iteration-count: 2;
    animation-direction: alternate;
  }

  /* Review animation-timing-function with Jo later during CSS discussion
  https://www.w3schools.com/css/css3_animations.asp
  */
  .contactcontainer .contactwrapperanimation a img {
    animation-name: iconspin;
    animation-duration: 1s;
    animation-iteration-count: 6;
    animation-direction: alternate;
    animation-timing-function: ease-in;
  }

  @keyframes iconspin {
    from {transform: rotateY(0deg)}
    to {transform: rotateY(-180deg)}
  }