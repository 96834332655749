/* Desktop Version */
@media (min-width: 1000px){
  .bannerContainer{
    position: relative;
    height: 80%;
    width: 100%;
  }

  .homebgimg{
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .introTextBox{
    position: absolute;
    top: 20%;
    width: 100%;
  }

  .lineOneIntro {
    font-size: 30px;
    animation-name: introText;
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-direction: normal;
    color: white;
    padding-left: 3%;
    opacity: 0;
  }
  
  .lineTwoIntro {
    width: 100%;
    font-size: 30px;
    opacity: 0;
    padding-left: 3%;
    color: white;
    animation-name: introText;
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-direction: normal;
    animation-delay: 3s;
  }

  .baseportfolio {
    position: relative;
    width: 30%;
    height: 30%;
    object-fit: cover;
    display: inline-block;
    text-align: center;
    margin-top: 1%;
    margin-bottom: 1%;
  }

  .aboutmetext{
    padding-top: 1%;
    margin-left: 20%;
    margin-right: 20%;
    font-size: 26px;
  }

  .portfoliodiv {
    position: relative;
    text-align: center;
    padding-top: 1.5%;
    padding-bottom: 1.5%;
  }
  
  .portfolio {
    position: relative;
    display: inline-block;
    text-align: center;
    width: 30%;
    height: 30%;
    object-fit: cover;
    animation-name: portfolioshrink;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-direction: normal;
    margin-top: 1%;
    margin-bottom: 1%;
  }
  
  .portfolio:hover {
    position: relative;
    display: inline-block;
    text-align: center;
    width: 30%;
    height: 30%;
    object-fit: cover;
  
    animation-name: portfolioexpand;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-direction: normal;
  }

  .portfoliotext {
    position: absolute;
    color: white;
    font-size: 30px;
    top: 40%;
    left: 50%;
    transform: translate(-50%, 0%); 
  }
  
  .portfoliotexthide {
    position: absolute;
    color: white;
    font-size: 30px;
    top: 40%;
    left: 50%;
    transform: translate(-50%, 0%); 
    animation-name: portfoliotexthide;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-direction: normal;
  }

  .headshot {
    height: 10%;
    width: 10%;
  }

  .contactmedesc{
    padding-top: 1%;
    padding-bottom: 1%;
    margin-left: 20%;
    margin-right: 20%;
    font-size: 26px;
  }

  .contactbox{
    display: flex;
    justify-content: space-evenly;
    margin-left: 20%;
    margin-right: 20%;
  }

  @keyframes portfolioexpand {
    from {width:30%; height: 30%}
    to {width: 40%; height: 40%}
  }
  
  @keyframes portfolioshrink {
    from {width:40%; height: 40%}
    to {width: 30%; height: 30%}
  }

  @keyframes introText {
    from {opacity: 0; padding-left: 15%}
    to {opacity: 1; padding-left: 20%}
  }
}

/* Mobile version */
@media (max-width: 1000px) {
  .bannerContainer{
    position: relative;
    height: 80%;
    width: 100%;
  }

  .homebgimg{
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .introTextBox{
    position: absolute;
    top: 15%;
    width: 100%;
  }

  .lineOneIntro {
    font-size: 24px;
    padding-right: 5%;
    animation-name: introText;
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-direction: normal;
    color: white;
    padding-left: 3%;
    opacity: 0;
  }
  
  .lineTwoIntro {
    width: 100%;
    font-size: 24px;
    padding-right: 5%;
    opacity: 0;
    padding-left: 3%;
    color: white;
    animation-name: introText;
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-direction: normal;
    animation-delay: 3s;
  }

  .baseportfolio {
    position: relative;
    width: 40%;
    height: 40%;
    object-fit: cover;
    display: inline-block;
    text-align: center;
    margin-top: 1%;
    margin-bottom: 1%;
  }

  .aboutmetext{
    padding-top: 1%;
    margin-left: 5%;
    margin-right: 5%;
    font-size: 20px;
  }

  .portfoliodiv {
    position: relative;
    text-align: center;
    padding-top: 1.5%;
    padding-bottom: 5%;
  }
  
  .portfolio {
    position: relative;
    display: inline-block;
    text-align: center;
    width: 40%;
    height: 40%;
    object-fit: cover;
    animation-name: portfolioshrink;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-direction: normal;
    margin-top: 1%;
    margin-bottom: 1%;
  }
  
  .portfolio:hover {
    position: relative;
    display: inline-block;
    text-align: center;
    width: 40%;
    height: 40%;
    object-fit: cover;
  
    animation-name: portfolioexpand;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-direction: normal;
  }

  .portfoliotext {
    position: absolute;
    color: white;
    font-size: 20px;
    top: 35%;
    left: 50%;
    transform: translate(-50%, 0%); 
  }
  
  .portfoliotexthide {
    position: absolute;
    color: white;
    font-size: 20px;
    top: 35%;
    left: 50%;
    transform: translate(-50%, 0%); 
    animation-name: portfoliotexthide;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-direction: normal;
  }

  .portfoliopadding{
    padding-bottom: 10%;
  }

  .headshot {
    height: 30%;
    width: 30%;
    padding-bottom: 5%;
  }

  .contactmedesc{
    padding-top: 5%;
    padding-bottom: 5%;
    margin-left: 5%;
    margin-right: 5%;
    font-size: 20px;
  }

  .contactbox{
    display: flex;
    justify-content: space-evenly;
    margin-left: 10%;
    margin-right: 10%;
  }

  @keyframes portfolioexpand {
    from {width:40%; height: 40%}
    to {width: 50%; height: 50%}
  }
  
  @keyframes portfolioshrink {
    from {width:50%; height: 50%}
    to {width: 40%; height: 40%}
  }

  @keyframes introText {
    from {opacity: 0; padding-left: 0%}
    to {opacity: 1; padding-left: 5%}
  }
}

.svgClass {
  animation-name: curtain;
  animation-duration: calc(var(--timeScale));
  animation-fill-mode: forwards;
  animation-direction: normal;
  animation-timing-function: ease-in;
}
 
/* The unordered list holding the data points, no list styling and no spacing */
.line-chart {
  list-style: none;
  margin: 0;
  padding: 0;
  
}

.landingWelcome{
  height: 20%;
}

.tooltip.fade.bs-tooltip-top.show > .tooltip-inner {
  padding: 0;
}

.topLevelDiv{
  height: 100%;
  position: relative;
}

.line {
  fill: none;
  stroke: #257AAD;
  stroke-width: 5;
}

.overlay {
fill: none;
pointer-events: all;
}

.area {
  opacity: 0.45;
  fill: url(#gradient);
}

.aboutme {
  text-align: center;
  padding: 1.5%;
  background-color: #f0f0f0;
}

.portfoliocontainer {
  position: relative;
}

.portfolioimg {
  width: auto;
  max-width: 100%;
  max-height: 100%;
  animation-name: portfolioimgdarken;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-direction: normal;
}

.portfolioimg:hover{
  animation-name: portfolioimglighten;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-direction: normal;
}

#contact {
  text-align: center;
  padding: 1.5%;
  background-color: rgb(240, 240, 240)
}

@keyframes portfolioimgdarken {
  from {filter: brightness(100%)}
  to {filter: brightness(50%)}
}

@keyframes portfolioimglighten {
  from {filter: brightness(50%)}
  to {filter: brightness(100%)}
}

@keyframes portfoliotexthide {
  from {opacity: 1}
  to {opacity: 0; font-size: 0px}
}

@keyframes curtain {
  from {width: 0}
  to {width: calc(var(--widthVal) * 1px)}
}