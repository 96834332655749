.footerbar{
    background-color: #333;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footerp{
    color: #b4b4b4;
}