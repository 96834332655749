/* Desktop Version */
@media (min-width: 1000px){
    .baseportfolio {
      position: relative;
      width: 30%;
      height: 30%;
      object-fit: cover;
      display: inline-block;
      text-align: center;
      margin-top: 1%;
      margin-bottom: 1%;
    }
    
    .portfolio {
      position: relative;
      display: inline-block;
      text-align: center;
      width: 30%;
      height: 30%;
      object-fit: cover;
      animation-name: portfolioshrink;
      animation-duration: 0.5s;
      animation-fill-mode: forwards;
      animation-direction: normal;
      margin-top: 1%;
      margin-bottom: 1%;
    }
    
    .portfolio:hover {
      position: relative;
      display: inline-block;
      text-align: center;
      width: 30%;
      height: 30%;
      object-fit: cover;
    
      animation-name: portfolioexpand;
      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-direction: normal;
    }
  
    .portfoliotext {
      position: absolute;
      color: white;
      font-size: 30px;
      top: 40%;
      left: 50%;
      transform: translate(-50%, 0%); 
    }
    
    .portfoliotexthide {
      position: absolute;
      color: white;
      font-size: 30px;
      top: 40%;
      left: 50%;
      transform: translate(-50%, 0%); 
      animation-name: portfoliotexthide;
      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-direction: normal;
    }

    .portfoliodesc {
      position: absolute;
      color: white;
      font-size: 15px;
      top: 55%;
      left: 50%;
      transform: translate(-50%, 0%); 
    }
    
    .portfoliodeschide {
      position: absolute;
      color: white;
      font-size: 15px;
      top: 55%;
      left: 50%;
      transform: translate(-50%, 0%); 
      animation-name: portfoliotexthide;
      animation-duration: 1;
      animation-fill-mode: forwards;
      animation-direction: normal;
    }

    .hiddenportfolio{
        width: auto;
        max-width: 100%;
        max-height: 100%;
        animation-name: portfolioimgdarken;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-direction: normal;
    }

    .portfolioimg {
        width: auto;
        max-width: 100%;
        max-height: 100%;
        animation-name: portfolioimgdarken;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-direction: normal;
      }
      
      .portfolioimg:hover{
        animation-name: portfolioimglighten;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-direction: normal;
      }
  
    @keyframes portfolioexpand {
      from {width:30%; height: 30%}
      to {width: 40%; height: 40%}
    }
    
    @keyframes portfolioshrink {
      from {width:40%; height: 40%}
      to {width: 30%; height: 30%}
    }
  }
  
  /* Mobile version */
  @media (max-width: 1000px) {  
    .baseportfolio {
      position: relative;
      width: 60%;
      height: 60%;
      object-fit: cover;
      display: inline-block;
      text-align: center;
      margin-top: 1%;
      margin-bottom: 1%;
    }
    
    .portfolio {
      position: relative;
      display: inline-block;
      text-align: center;
      width: 80%;
      height: 80%;
      object-fit: cover;
      animation-name: portfolioexpand;
      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-direction: normal;
      margin-top: 1%;
      margin-bottom: 1%;
    }
  
    .portfoliotext {
      position: absolute;
      color: white;
      font-size: 20px;
      top: 35%;
      left: 50%;
      transform: translate(-50%, 0%); 
    }
    
    .portfoliotexthide {
      position: absolute;
      color: white;
      font-size: 20px;
      top: 35%;
      left: 50%;
      transform: translate(-50%, 0%); 
      animation-name: portfoliotexthide;
      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-direction: normal;
    }

    .portfoliodesc {
      position: absolute;
      color: white;
      font-size: 12px;
      top: 55%;
      left: 50%;
      transform: translate(-50%, 0%); 
    }
    
    .portfoliodeschide {
      position: absolute;
      color: white;
      font-size: 12px;
      top: 55%;
      left: 50%;
      transform: translate(-50%, 0%); 
      animation-name: portfoliotexthide;
      animation-duration: 1;
      animation-fill-mode: forwards;
      animation-direction: normal;
    }
  
    .hiddenportfolio{
        width: auto;
        max-width: 100%;
        max-height: 100%;
        animation-name: portfolioimgdarken;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-direction: normal;
    }

    .portfolioimg{
        animation-name: portfolioimglighten;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-direction: normal;
      }
  
    @keyframes portfolioexpand {
      from {width:60%; height: 60%}
      to {width: 80%; height: 80%}
    }
    
    @keyframes portfolioshrink {
      from {width:80%; height: 80%}
      to {width: 60%; height: 60%}
    }

    @keyframes portfolioimgdarken {
        from {filter: brightness(100%)}
        to {filter: brightness(50%)}
      }
      
    @keyframes portfolioimglighten {
        from {filter: brightness(50%)}
        to {filter: brightness(100%)}
    }
  }
  
  .portfoliocontainer {
    position: relative;
  }
  
  @keyframes portfoliotexthide {
    from {opacity: 1}
    to {opacity: 0; font-size: 0px}
  }
