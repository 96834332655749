/* Desktop Version */
@media (min-width: 1000px){
  .headerDiv {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: auto;
    width: 100%;
  }

  .logorefsep{
    height: 100%;
    display: flex;
    justify-content: space-between;
  }

  .inlineHeader {
    /* position: sticky; */
    display: flex;
    justify-content: space-between;
    height: 100%;
    padding-right: 20%;
  }

  .logoBox{
    display: flex;
    align-items: center;
    height: 100%;
    width: fit-content;
    margin-left: 20%;
  }

  .logoimg{
    max-height: 90%;
    max-width: 90%;
  }

  .headerDiv > a {
    margin-left: 20px;
    margin-right: 20px;
  }

  .headerInside {
    width: fit-content;
    font-size: 20px;
    text-decoration: none;
    height: fit-content;
    padding: 10px;
  }
}

/* Mobile Version */
@media (max-width: 1000px){
  .headerDiv {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: auto;
    width: 100%;
    /* padding-right: 40%; */
  }

  .logorefsep{
    height: 90%;
    display: flex;
    justify-content: space-between;
  }

  .logoBox{
    display: flex;
    align-items: center;
    height: 100%;
    width: fit-content;
    /* margin-left: 30%; */
  }

  .logoimg{
    max-height: 90%;
    max-width: 90%;
  }

  .inlineHeader {
    position: sticky;
    display: flex;
    justify-content: space-between;
    height: 100%;
  }

  .headerInside {
    width: fit-content;
    font-size: px;
    text-decoration: none;
    height: fit-content;
    padding: 10px;
  }
}

.headerbar {
  width: 100%;
  height: 10%;
  position: var(--position-val);
  top: 0;
  z-index: 999;
  background-color: var(--headerStyle);
}

.animatebar{
  width: 100%;
  height: 10%;
  position: var(--position-val);
  top: 0;
  z-index: 999;
  background-color: var(--headerStyle);
  animation-name: headeranimatein;
  animation-duration: 1s;
}

p {
  margin: 0px;
}

a {
  cursor: pointer;
}

@keyframes headeranimatein {
  from {transform: translateY(-100%)}
  to {transform: translateY(0)}
}